'use strict';

var logger 			= require('../../utils/logger.js');
var vrmApi 			= require('../../api/vrm-api.js');

var FluxDispatcher 	= require('../dispatchers/flux-dispatcher.js');
var Constants 		= require('../constants/vrm-lookup-constants.js');

var ActionTypes = Constants.ActionTypes;

function log(source, actionType, data) {
	logger.debug('VRM lookup', {
		message: 'Create ' + source + ' action',
		action: actionType,
		data: data
	});
}

function createServerAction(actionType, data) {
	log('server', actionType, data);
	FluxDispatcher.dispatchServerAction({
		type: actionType,
		data: data
	});
}

var Actions = {
	lookupVrm: function (vehicleType, vrm, recaptchaResponse, vrmPathSegment) {
		createServerAction(ActionTypes.LOOKUP_VRM);
		vrmApi.getVrmInfo(vehicleType, vrm, recaptchaResponse, vrmPathSegment, {
			successCallback: function (data) {
				if (data) {
					createServerAction(ActionTypes.LOOKUP_VRM_SUCCESS, JSON.parse(data));
				} else {
					createServerAction(ActionTypes.LOOKUP_VRM_ERROR);
				}
			},
			errorCallback: function () {
				createServerAction(ActionTypes.LOOKUP_VRM_ERROR);
			}
		});
	},

	storeVrm: function (data, redirectUrl) {
		vrmApi.storeVrm(data, {
			completeCallback: function() {
				if (redirectUrl) {
					window.location = redirectUrl;
				}
			}
		});
	},

	navigatePage: function (url) {
		if (url) {
			window.location = url;
		}
	}
};

module.exports = Actions;