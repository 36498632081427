'use strict';

require('jquery-validation');

var $ = require('jquery');

require('../vendor.js');
require('../plugins.js');

var advert = require('../adverts/advert.js');

// structures
var backToTop  = require('../structure/back-to-top.js');
var betaMessage = require('../structure/beta-message.js');
var modelSwitcher = require('../structure/model-switcher.js');
var primaryNav = require('../structure/primary-nav.js');
var siteNav = require('../structure/site-nav.js');
var siteSearch = require('../structure/site-search.js');

// components
var panels = require('../components/panels.js');
var urlDropdown = require('../components/url-dropdown.js');
var featuredCarousel = require('../components/featured-carousel.js');
var newsletterSignup = require('../components/newsletter-signup.js');

// react
var reactControls = require('../react/react-controls.js');

// ui
var anchors = require('../ui/anchors.js');
var images = require('../ui/images.js');
var sticky = require('../ui/sticky.js');

// watchers
var heightWatcher = require('../watchers/height-watcher.js');
var scrollWatcher = require('../watchers/scroll-watcher.js');
var screenSizeWatcher = require('../watchers/screen-size-watcher.js');

// utils
var graph = require('../utils/graph-data.js');
var debugHelper = require('../utils/debug-helper.js');
var config = require('../utils/config.js');
var logger = require('../utils/logger.js');
var analytics = require('../utils/analytics.js');
var polyfill = require('../utils/polyfill.js');
var permutive = require('../utils/permutive.js');
var foundationWrapper = require('../utils/foundation-wrapper.js');

function initConfig() {
	if (typeof config === 'undefined') {
		window.alert('The parkersConfig JSON object must be defined before parkers.js is loaded.');
	} else {
		logger.info('Parkers config', config);
	}
}

function startWatchers() {
	heightWatcher.start();
	scrollWatcher.start();
	screenSizeWatcher.start();
}

function loadStructure() {
	siteNav.init();
	primaryNav.init();
	siteSearch.init();
	backToTop.init();
	betaMessage.init();
	modelSwitcher.init();
}

function loadUI() {
	anchors.init();
	images.init();
	sticky.init();
}

function loadContent() {
	analytics.initClickTracking();
	panels.init();
	urlDropdown.init();
	featuredCarousel.init();
	newsletterSignup.init();
}

function initialiseBasePage() {
	polyfill.init();
	initConfig();
	startWatchers();
	$(document).foundation();
	loadStructure();
	loadUI();
	foundationWrapper.init();

	permutive.init();

	//Expose parkers_dfp for 3rd parties
	window.parkers_dfp = {
		init: function (adConfig) {
			// Init sticky before ads so that scrollmagic DOM manipulation is complete before the ad iframes are injected.
			// Otherwise they end up empty.
			sticky.init();
			advert.init(adConfig);
		},
		setupSkin: function (backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, clickThroughUrl) {
			advert.setupSkin(backgroundImageDesktop, backgroundImageTablet, backgroundImageMobile, clickThroughUrl);
		},
		disableStickyMpus: function () {
			advert.disableStickyMpus();
		}
	};


	reactControls.render();
	loadContent();
	debugHelper.init();
	graph.enqueue();

	logger.debug('Initialising page - after ' + logger.secondsSincePageStart());
}

module.exports = {
	initBasePage: initialiseBasePage,
	init: initialiseBasePage,
	utils: {
		analytics: analytics
	},
	advert: {
		hideGumGum: advert.hideGumGum,
		showGumGum: advert.showGumGum
	}
};