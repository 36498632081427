'use strict';

var baseApi = require('./base-api.js');
var logger = require('../utils/logger.js');

function getApiUrl(vehicleType) {
	if (!vehicleType) {
		logger.error('VRM API - a vehicle type ("cars" or "vans") must be specified.');
		return;
	}
	
	return vehicleType + '/vrm';
}

module.exports = {
	getVrmInfo: function (vehicleType, vrm, recaptchaResponse, vrmPathSegment, options) {
		var url = getApiUrl(vehicleType, vrm, recaptchaResponse, vrmPathSegment);
		var data = {
			vrm: vrm,
			recaptchaResponse: recaptchaResponse,
			pathSegment: vrmPathSegment
		};

		baseApi.post(url, data, options);
	},

	storeVrm: function (data, options) {
		var url = 'vrm/store';
		baseApi.post(url, data, options);
	}
};