/** @jsx React.DOM */'use strict';

var $ 			= require('jquery');
var React 		= require('react');
var Recaptcha 	= require('react-recaptcha');
var analytics 	= require('../../utils/analytics.js');
var logger	 	= require('../../utils/logger.js');

var Actions 	= require('../actions/vrm-lookup-actions.js');
var Store 		= require('../stores/vrm-lookup-store.js');

let recaptchaInstance;
let recaptchaReadyCheck;

const isRecaptchaReady = () => typeof window !== 'undefined'
	&& typeof window.grecaptcha !== 'undefined'
	&& typeof window.grecaptcha.render === 'function';

const updateRecaptchaReadyState = () => {
	if (isRecaptchaReady()) {
		recaptchaInstance.render();
		clearInterval(recaptchaReadyCheck);
	}
}

module.exports = React.createClass({
	/* #region React functions */
	propTypes: {
		vehicleType: React.PropTypes.string.isRequired,
		promptText: React.PropTypes.string,
		vrmPathSegment: React.PropTypes.string,
		inlineLayout: React.PropTypes.bool,
		gaLabel: React.PropTypes.string,
		recaptchaSiteKey: React.PropTypes.string
	},

	getDefaultProps: function() {
		return {
			promptText: 'Go',
		}
	},

	getInitialState: function () {
		return Store.getState();
	},

	componentDidUpdate: function () {
		$(document).foundation('equalizer', 'reflow');
		
		if (!this.state.isLoading && this.state.confirmationUrl) {

			var gaLabel = this.props.gaLabel ? this.props.gaLabel : 'Valuation Landing';
			
			analytics.trackEvent('VRM Lookup', 'Successful VRM Lookup', gaLabel);
			var vrmData = {
				'vrm': this.state.vrmData.vrm,
				'derivativeId': this.state.vrmData.derivativeId,
				'fuelType': this.state.vrmData.fuelType,
				'firstRegistered': this.state.vrmData.firstRegistered,
				'plateAndYear': this.state.vrmData.plateAndYear,
				'vrmCookieName': this.state.vrmData.vrmCookieName};
			Actions.storeVrm(vrmData, this.state.confirmationUrl);
		}
	},

	componentWillMount: function () {
		Store.addChangeListener(this.onStoreChange);
	},

	componentWillUnmount: function () {
		Store.removeChangeListener(this.onStoreChange);
		clearInterval(recaptchaReadyCheck);
	},

	onStoreChange: function () {
		this.setState(Store.getState());
	},
	/* #endregion */

	/* #region Render methods */
	render: function () {
		logger.debug('VRM lookup', {
			message: 'Render',
			state: this.state
		});

		var errorMessage;
		var loadingItem; 
		var layout;

		if (this.state.isLoading || this.state.confirmationUrl){
			loadingItem = <span className="vrm-lookup__button__spinner"></span>
		}

		if (this.state.errorMessage) {
			errorMessage = <span className="error">{this.state.errorMessage}</span>;
		}

		layout = this.props.inlineLayout 
			? this.getInlineLayout(loadingItem, errorMessage) 
			: this.getStackedLayout(loadingItem, errorMessage);

		recaptchaReadyCheck = setInterval(updateRecaptchaReadyState, 500);
		
		return (
			<form className="vrm-lookup" onSubmit={this.onSubmit}>
				{layout}
			</form>
		);
	},

	getStackedLayout: function (loadingItem, errorMessage) {
		return (
			<span>
				<input type="text" className="vrm-lookup__input" placeholder="your reg" />
				{errorMessage}
				<div className="vrm-lookup__submit-panel">
					<button className="vrm-lookup__button button" disabled={this.state.isLoading} type="submit">
						{this.props.promptText}
						{loadingItem}
					</button>
				</div>
				
				<div className="vrm-lookup__recaptcha-panel">
					<Recaptcha 
						sitekey={this.props.recaptchaSiteKey}
						className="vrm-lookup__recaptcha-panel__recaptcha"
						ref={e => recaptchaInstance = e}
						verifyCallback={this.onRecaptchaVerify}
						render="explicit"
					/>
				</div>
			</span>
		);
	},

	getInlineLayout: function (loadingItem, errorMessage) {
		return (
			<span>
				<div className="vrm-lookup__inline-layout">
					<input type="text" className="vrm-lookup__input" placeholder="your reg" />
					<div className="vrm-lookup__submit-panel">
						<button className="vrm-lookup__button button" disabled={this.state.isLoading} type="submit">
							{this.state.isLoading ? loadingItem : this.props.promptText}
						</button>
					</div>
					
					<div className="vrm-lookup__recaptcha-panel">
						<Recaptcha 
							sitekey={this.props.recaptchaSiteKey}
							className="vrm-lookup__recaptcha-panel__recaptcha"
							ref={e => recaptchaInstance = e}
							verifyCallback={this.onRecaptchaVerify}
							render="explicit"
						/>
					</div>
				</div>
				{errorMessage}
			</span>
		);
	},

	/* #endregion */

	/* #region Event handlers */
	onSubmit: function (e) {
		e.preventDefault();
		var vrm = $('.vrm-lookup__input').val();
		
		if (vrm) {
			var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
			var $submitPanel = $.findByBem('vrm-lookup', 'submit-panel');
			
			recaptchaInstance.reset();
			
			$submitPanel.hide();
			$recaptchaPanel.show();
		}
	},

	onRecaptchaVerify: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();
		
		var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
		var $submitPanel = $.findByBem('vrm-lookup', 'submit-panel');

		if (vrm) {
			$recaptchaPanel.hide();
			$submitPanel.show();

			recaptchaInstance.reset();

			// remove whitespaces
			vrm = vrm.replace(/\s+/g, '');
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPathSegment);
		}
	}
	/* #endregion */
});