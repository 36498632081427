var logger = require('../utils/logger.js');

var tcfVersion = 2;
var BauerVendorName = 'Bauer Media Group';

var eventNames = {
    uiShown: 'cmpuishown',
    userActionComplete: 'useractioncomplete',
    subsequentPageLoad: 'tcloaded'
};

// These values match those defined in the Vendor Management page on the Sourcepoint dashboard.
// Purposes have consistent preset names, while the IDs these names map to differ between vendor lists.
var purposeNames = {
	storeAndAccessInfo: 'Store and/or access information on a device',
	selectPersonalisedAds: 'Use profiles to select personalised advertising',
	createPersonalisedAdsProfile: 'Create profiles for personalised advertising'
};

function hasPurposeConsent(vendorConsents, purposeName) {
	if (!vendorConsents || !vendorConsents.consentedPurposes) {
		logger.warn('Vendor consent data is not set');
		return false;
	}

	return vendorConsents.consentedPurposes.some(function (purpose) {
			return purpose.name === purposeName;
		});
}

function hasVendorConsent(vendorConsents, vendorName) {
	if (!vendorConsents || !vendorConsents.consentedVendors) {
		logger.warn('Vendor consent data is not set');
		return false;
	}

	return vendorConsents.consentedVendors.some(function (vendor) {
		return vendor.name === vendorName;
	});
}

function addTcfApiHandler(eventStatus, callback) {
	window.__tcfapi('addEventListener', tcfVersion, function (tcData, success) {
		if (!success) {
			logger.warn('Failed to add tcf api handler');
			return;
		}

		if (tcData.eventStatus === eventNames.uiShown && eventStatus === eventNames.uiShown) {
			callback();
		}

		if (tcData.eventStatus === eventNames.userActionComplete && eventStatus === eventNames.userActionComplete) {
			callback();
		}

		if (tcData.eventStatus === eventNames.subsequentPageLoad && eventStatus === eventNames.subsequentPageLoad) {
			window.__tcfapi('removeEventListener', tcfVersion, function (success) {
				if (success) {
					callback();
				}
			}, tcData.listenerId);
		}
	});
}

function onRetrieveBauerConsent(successCallback, failureCallback) {
	window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
		if (!success) {
			logger.warn('Failed to add bauer consent handler');
			return;
		}

		if (hasPurposeConsent(vendorConsents, purposeNames.storeAndAccessInfo) && hasVendorConsent(vendorConsents, BauerVendorName)) {
			successCallback();
		} else {
			failureCallback();
		}
	});
}

function onRetrieveVendorAndPurposeConsents(successCallback, failureCallback) {
	var hasBauerConsent = false;
	var hasStoreAndAccessInfoConsent = false;
	var hasSelectPersonalisedAds = false;
	var hasCreatePersonilisedAdsProfile = false;

	window.__tcfapi('getCustomVendorConsents', tcfVersion, function (vendorConsents, success) {
		if (!success) {
			logger.warn('Failed to add bauer consent handler');
			return;
		}

		if (hasVendorConsent(vendorConsents, BauerVendorName)) {
			hasBauerConsent = true;
		}
		
		vendorConsents.consentedPurposes.forEach(function (purpose) {
			if (purpose.name === purposeNames.storeAndAccessInfo) {
				hasStoreAndAccessInfoConsent = true;
			}

			if (purpose.name === purposeNames.selectPersonalisedAds) {
				hasSelectPersonalisedAds = true;
			}

			if (purpose.name === purposeNames.createPersonalisedAdsProfile) {
				hasCreatePersonilisedAdsProfile = true;
			}
		});

		if (hasBauerConsent && hasStoreAndAccessInfoConsent && hasSelectPersonalisedAds && hasCreatePersonilisedAdsProfile) {
			successCallback();
		} else {
			failureCallback();
		}
	});
}

module.exports = {
    eventNames: eventNames,
	addTcfApiHandler: addTcfApiHandler,
	onRetrieveBauerConsent: onRetrieveBauerConsent,
	onRetrieveVendorAndPurposeConsents: onRetrieveVendorAndPurposeConsents
};