/** @jsx React.DOM */'use strict';

var React = require('react');
var Recaptcha = require('react-recaptcha');
const logger = require('../../utils/logger');
var Actions = require('../actions/taxonomy-selector-actions.js');

let recaptchaInstance;
let recaptchaReadyCheck;

const isRecaptchaReady = () => typeof window !== 'undefined'
	&& typeof window.grecaptcha !== 'undefined'
	&& typeof window.grecaptcha.render === 'function';

const updateRecaptchaReadyState = () => {
	if (isRecaptchaReady()) {
		recaptchaInstance.render();
		clearInterval(recaptchaReadyCheck);
	}
}

module.exports = React.createClass({

	propTypes: {
		mobilePromptText: React.PropTypes.string,
		desktopPromptText: React.PropTypes.string,
		isLoading: React.PropTypes.bool,
		errorMessage: React.PropTypes.string,
		recaptchaSiteKey: React.PropTypes.string,
		vrmPath: React.PropTypes.string,
		vehicleType: React.PropTypes.string,
	},

	componentDidMount: function () {
		var $vrmLookupBtn = $.findByBem('vrm-lookup', 'button');
		$vrmLookupBtn.click('on', function (e) {
			e.preventDefault();
		});
	},

	componentWillUnmount: function () {
		clearInterval(recaptchaReadyCheck);
	},	

	render: function () {
		var errorMessage;
		var loadingItem;
		var promptText = this.getPromptText();

		if (this.props.isLoading) {
			loadingItem = <span className="vrm-lookup__button__spinner"></span>
		}

		if (this.props.errorMessage) {
			errorMessage = <span className="error">{this.props.errorMessage}</span>;
		}

		recaptchaReadyCheck = setInterval(updateRecaptchaReadyState, 500);

		return (
			<span>
				<div className="vrm-lookup__inline-layout">
					<input type="text" className="vrm-lookup__input" placeholder="your reg" />
					<button className="vrm-lookup__button button" disabled={this.props.isLoading} onClick={this.onVrmLookupButtonClick}>
						{this.props.isLoading ? loadingItem : promptText}
					</button>
				</div>
				<div className="vrm-lookup__recaptcha-panel">
					<Recaptcha
						sitekey={this.props.recaptchaSiteKey}
						className="vrm-lookup__recaptcha-panel__recaptcha"
						ref={e => recaptchaInstance = e}
						verifyCallback={this.onRecaptchaVerify}
						render="explicit"
					/>
				</div>

				{errorMessage}
			</span>
		);
	},

	getPromptText: function () {
		return (
			<span>
				<span className="hide-for-large-up">{this.props.mobilePromptText}</span>
				<span className="show-for-large-up">{this.props.desktopPromptText}</span>
			</span>
		);
	},

	onVrmLookupButtonClick: function (e) {
		e.preventDefault();
		var vrm = $('.vrm-lookup__input').val();

		if (vrm) {
			var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
			var $vrmLookUpButton = $.findByBem('vrm-lookup', 'button');

			recaptchaInstance.reset();

			$vrmLookUpButton.hide();
			$recaptchaPanel.show();
		}
	},

	onRecaptchaVerify: function (recaptchaResponse) {
		var vrm = $('.vrm-lookup__input').val();

		var $recaptchaPanel = $.findByBem('vrm-lookup', 'recaptcha-panel');
		var $vrmLookUpButton = $.findByBem('vrm-lookup', 'button');

		if (vrm) {
			$recaptchaPanel.hide();
			$vrmLookUpButton.show();

			recaptchaInstance.reset();
			
			// remove whitespaces
			vrm = vrm.replace(/\s+/g, '');
			Actions.lookupVrm(this.props.vehicleType, vrm, recaptchaResponse, this.props.vrmPath);
		}
	}
});